import React, { useMemo } from 'react';
import { ICustomFields, IFormElement, IOption } from '@types';
import {
  CCard,
  CCardBody,
  CCardHeader,
  CInputGroup,
  CInputGroupText,
  CRow
} from '@coreui/react-pro';
import {
  CheckButton,
  Col,
  DatePicker,
  FileAttachment,
  FormCheck,
  FormInput,
  FormLabel,
  FormMultiSelect,
  FormPanel,
  FormSelect,
  FormSwitch,
  FormTable,
  FormTextarea,
  HtmlEditor,
  JsonEditor,
  ListView,
  RichTextEditor,
  Row,
  SmartFormMultiSelect,
  SmartFormSelect
} from '@component';

interface FormFieldProps<T> {
  field: IFormElement;
  loading?: boolean;
  data: any;
  changedData: any;
  customFields?: ICustomFields<T>;
}

export default function FormField<T>(props: FormFieldProps<T>) {
  const field: IFormElement = props.field;
  const defaultValue = useMemo(() =>
      getDefaultValue(field, props.data, props.data),
    [props.data, field.name]);

  const value = useMemo(() =>
      field.value
        ? field.value(props.changedData, props.data)
        : getDefaultValue(field, props.changedData, props.data),
    [field.value, field.name, props.changedData]);

  const filterOptions = useMemo(() =>
      field.select?.filterOptions
        ? (option: IOption) => field.select?.filterOptions?.call(null, option, props.changedData)
        : undefined,
    [field.select?.filterOptions, props.changedData]);

  switch (field.type) {
    case 'label':
      return (
        <FormLabel
          hidden={field.hidden}
          required={field.required}
          description={field.tooltip}
        >
          {field.label}
        </FormLabel>
      );

    case 'input-group':
      return (
        <>
          <FormLabel
            hidden={field.hidden}
            required={field.required}
            description={field.tooltip}
          >
            {field.label}
          </FormLabel>
          <CInputGroup>
            {field.fields?.map((field, i) =>
              <FormField
                field={field}
                data={props.data}
                changedData={props.changedData}
                customFields={props.customFields}
                key={i} />
            )}
          </CInputGroup>
        </>
      );

    case 'input-group-text':
      return (
        <CInputGroupText title={field.title} component="label" hidden={field.hidden}>
          {field.fields?.map((field, i) =>
            <FormField
              field={field}
              data={props.data}
              changedData={props.changedData}
              customFields={props.customFields}
              key={i} />
          )}
          {field.title}
        </CInputGroupText>
      );

    case 'input-panel':
      return (
        <FormPanel
          label={field.label}
          description={field.tooltip}
          required={field.required}
          hidden={field.hidden}
        >
          {field.fields?.map((field, i) =>
            <div key={i}>
              <FormField
                field={field}
                data={props.data}
                changedData={props.changedData}
                customFields={props.customFields} />
            </div>
          )}
        </FormPanel>
      );

    case 'text':
    case 'email':
    case 'phone':
    case 'number':
      return (
        <FormInput
          type={field.type}
          name={field.name}
          label={field.label}
          description={field.tooltip}
          placeholder={field.placeholder}
          //defaultValue={defaultValue}
          value={value}
          required={field.required}
          readOnly={field.readOnly}
          disabled={field.disabled}
          hidden={field.hidden}
          min={field.minLength}
          max={field.maxLength}
          maxLength={field.maxLength}
          prompt={field.prompt}
        />
      );

    case 'textarea':
      return (
        <FormTextarea
          name={field.name}
          title={field.title}
          label={field.label}
          description={field.tooltip}
          placeholder={field.placeholder}
          defaultValue={defaultValue}
          required={field.required}
          readOnly={field.readOnly}
          disabled={field.disabled}
          hidden={field.hidden}
          maxLength={field.maxLength}
          // @ts-ignore
          rows={field.rows}
        />
      );

    case 'select':
      return (
        <>
          {field.multiple
            ? field.externalData
              ? (
                <SmartFormMultiSelect
                  name={field.name}
                  label={field.label}
                  description={field.tooltip}
                  placeholder={field.placeholder}
                  defaultValue={defaultValue}
                  required={field.required}
                  readOnly={field.readOnly}
                  disabled={field.disabled}
                  hidden={field.hidden}
                  valueType={field.valueType}
                  filterOptions={filterOptions}
                  virtualScroller={field.select?.virtualScroller}
                  visibleItems={field.select?.visibleItems}
                  selectAll={field.select?.selectAll}
                  emptyOption={field.select?.emptyOption}
                  loading={!props.loading}
                  actionUrl={
                    typeof field.externalData.url === 'function'
                      ? field.externalData.url(props.changedData, props.data)
                      : field.externalData.url
                  }
                  params={
                    typeof field.externalData.params === 'function'
                      ? field.externalData.params(props.changedData, props.data)
                      : field.externalData.params
                  }
                  valueKey={field.externalData?.valueKey}
                  disabledIfEmpty={field.select?.disabledIfEmpty}
                  hideDisabledOptions={field.select?.hideDisabledOptions}
                />
              )
              : (
                <FormMultiSelect
                  name={field.name}
                  label={field.label}
                  description={field.tooltip}
                  placeholder={field.placeholder}
                  defaultValue={defaultValue}
                  required={field.required}
                  readOnly={field.readOnly}
                  disabled={field.disabled}
                  hidden={field.hidden}
                  options={field.options}
                  valueType={field.valueType}
                  filterOptions={filterOptions}
                  virtualScroller={field.select?.virtualScroller}
                  visibleItems={field.select?.visibleItems}
                  selectAll={field.select?.selectAll}
                  disabledIfEmpty={field.select?.disabledIfEmpty}
                />
              )
            : field.externalData
              ? (
                <SmartFormSelect
                  name={field.name}
                  label={field.label}
                  description={field.tooltip}
                  placeholder={field.placeholder}
                  defaultValue={defaultValue}
                  value={value}
                  required={field.required}
                  readOnly={field.readOnly}
                  disabled={field.disabled}
                  hidden={field.hidden}
                  filterOptions={filterOptions}
                  htmlSize={field.select?.visibleItems}
                  emptyOption={field.select?.emptyOption}
                  loading={!props.loading}
                  actionUrl={
                    typeof field.externalData.url === 'function'
                      ? field.externalData.url(props.changedData, props.data)
                      : field.externalData.url
                  }
                  params={
                    typeof field.externalData.params === 'function'
                      ? field.externalData.params(props.changedData, props.data)
                      : field.externalData.params
                  }
                  valueKey={field.externalData?.valueKey}
                  hideDisabledOptions={field.select?.hideDisabledOptions}
                />
              )
              : (
                <FormSelect
                  name={field.name}
                  label={field.label}
                  description={field.tooltip}
                  placeholder={field.placeholder}
                  defaultValue={defaultValue}
                  required={field.required}
                  readOnly={field.readOnly}
                  disabled={field.disabled}
                  hidden={field.hidden}
                  options={field.options}
                />
              )}
        </>
      );

    case 'switch':
      return (
        <FormSwitch
          name={field.name}
          label={field.label}
          description={field.tooltip}
          placeholder={field.placeholder}
          defaultChecked={defaultValue}
          required={field.required}
          readOnly={field.readOnly}
          disabled={field.disabled}
          hidden={field.hidden}
        />
      );

    case 'radio':
    case 'checkbox':
      return (
        <FormCheck
          type={field.type}
          title={field.title}
          name={field.name}
          label={field.label}
          description={field.tooltip}
          placeholder={field.placeholder}
          defaultChecked={defaultValue}
          required={field.required}
          readOnly={field.readOnly}
          disabled={field.disabled}
          hidden={field.hidden}
          inline={field.inline}
        />
      );

    case 'check-button':
      return (
        <CheckButton
          title={field.title}
          name={field.name}
          defaultChecked={defaultValue}
          readOnly={field.readOnly}
          disabled={field.disabled}
        />
      );

    case 'hidden':
      return (
        <input
          hidden
          name={field.name}
          defaultChecked={defaultValue}
          defaultValue={defaultValue}
        />
      );

    case 'custom':
      return props.customFields && field.name && props.customFields[field.name]
        ? props.customFields[field.name](field, props.data, props.changedData)
        : <></>;

    case 'date':
    case 'date-time':
      return (
        <DatePicker
          name={field.name}
          label={field.label}
          description={field.tooltip}
          placeholder={field.placeholder}
          defaultValue={defaultValue}
          required={field.required}
          readOnly={field.readOnly}
          disabled={field.disabled}
          hidden={field.hidden}
          timepicker={field.type === 'date-time'}
        />
      );

    case 'html-editor':
      return (
        <RichTextEditor
          name={field.name ?? ''}
          label={field.label}
          description={field.tooltip}
          disabled={field.disabled}
          required={field.required}
          value={defaultValue}
          hidden={field.hidden}
          maxLength={field.maxLength}
        />
      );

    case 'file':
      return (
        <FileAttachment
          name={field.name ?? ''}
          label={field.label}
          description={field.tooltip}
          readOnly={field.readOnly}
          required={field.required}
          hidden={field.hidden}
          multiple={field.multiple}
          maxLength={field.maxLength}
          minLength={field.minLength}
          accept={field.accept}
          value={defaultValue}
          datatype={field.file?.datatype}
          fromForm={field.fromForm}
          draggable={field.draggable}
        />
      );

    case 'card':
      return (
        <CCard
          hidden={field.hidden}
          style={{
            boxShadow: field.card?.boxShadow
              ? field.card.boxShadow
              : undefined
          }}
        >
          {field.label && (
            <CCardHeader>
              {field.label}
            </CCardHeader>
          )}
          <CCardBody>
            <CRow>
              {field.fields?.map((item, i) => {

                  return (
                    <Col col={item.col} key={i}>
                      <div className="mb-2">
                        <FormField
                          field={item}
                          data={props.data}
                          changedData={props.changedData}
                          customFields={props.customFields}
                        />
                      </div>
                    </Col>
                  );
                }
              )}
            </CRow>
          </CCardBody>
        </CCard>
      );

    case 'table':
      return (
        <FormTable
          name={field.name ?? ''}
          label={field.label}
          description={field.tooltip}
          items={defaultValue}
          total={defaultValue?.length}
          columns={field.table?.columns}
          formData={props.changedData}
          required={field.required}
          hidden={field.hidden}
          createButton={field.table?.createButton}
        />
      );

    case 'list':
      return (
        <Col col={field.col}>
          <ListView
            data={props.data}
            items={field.list?.items ?? []}
            className={field.list?.className}
            label={field.label}
            description={field.tooltip}
            hidden={field.hidden}
          />
        </Col>
      );

    case 'row':
      return (
        <Row row={field.row}>
          {field.fields?.map((field, i) =>
            <FormField
              key={i}
              field={field}
              data={props.data}
              changedData={props.changedData}
              customFields={props.customFields}
            />
          )}
        </Row>
      );

    case 'col':
      return (
        <Col col={field.col}>
          {field.fields?.map((field, i) =>
            <FormField
              key={i}
              field={field}
              data={props.data}
              changedData={props.changedData}
              customFields={props.customFields}
            />
          )}
        </Col>
      );

    case 'json':
      return (
        <Col col={field.col}>
          <JsonEditor
            name={field.name!}
            label={field.label}
            description={field.tooltip}
            required={field.required}
            value={defaultValue}
          />
        </Col>
      );

    case 'html':
      return (
        <Col col={field.col}>
          <HtmlEditor
            name={field.name!}
            label={field.label}
            description={field.tooltip}
            required={field.required}
            value={defaultValue}
          />
        </Col>
      );

    default:
      return (
        <></>
      );
  }
}

const getDefaultValue = (field: IFormElement, data: any, initial: any) => {
  if (field.name) {
    const namePath = field.name.split('.').filter(x => x !== '');
    if (namePath.length === 0) {
      return field.defaultValue;
    }
    if (namePath.length === 1) {
      if (typeof field.value === 'function') {
        return field.value(data, initial) ?? field.defaultValue;
      }
      return data[namePath[0]] ?? field.defaultValue;
    }
    let value: any = data;
    for (let i = 0; i < namePath.length; i++) {
      if (value) {
        value = value[namePath[i]];
      }
    }
    return value;
  }

  return field.defaultValue;
};